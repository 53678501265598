.containerPlayer {
    padding: 20px 30px;
    align-content: center;
    width: 100%;
}

/* Media page design */

.album-left-column {
    background-color: #000;
    text-align: center;
}

.album-right-column {
    background-color: hsl(0, 0%, 13%);
    padding: 10px 50px !important;
}

.cover-left {
    margin: 20px 0 0 0;
    width: 90%;
}

#listContainer {
    width: 100%;
    float: left;
}

#listContainer ul {
    background-color: transparent;
    clear: both;
    cursor: pointer;
    padding-left: 0 !important;
}

#listContainer li:hover,
#listContainer li:nth-child(even):hover {
    background-color: transparent;
    color: #fff;
}

#listContainer li a {
    text-decoration: none;
    color: #fff;
}

#listContainer li {
    height: 70px;
}

#listContainer li.activeAudio {
    background-color: transparent;
}

.activeAudio img {
    transition: all 0.2s ease-in-out;
}

.activeAudio img:hover {
    transform: scale(1.1);
}

#playListContainer li {
    display: none;
}

#playListContainer li.activeAudio {
    display: block;
    border-bottom: 1px dotted #7e7e7e;
    margin-bottom: 10px;
}

#playListContainer li.activeAudio:last-child {
    border-bottom: none;
}

#listContainer li a img {
    float: left;
}

#listContainer li a h5 {
    margin-left: 122px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    transform: translateY(33%);
}

.songs-play-full {
    display: block;
    margin-left: 32px;
    float: left;
    font-size: 10px;
    border: 1px solid #b79a20;
    border-radius: 5px;
    padding: 10px 10px;
    margin-top: 5px;
    min-width: 250px;
}

.songs-play:last-child {
    margin-left: 10px;
}

.songs-play-full:hover {
    background-color: #fff;
    color: #000 !important;
}

.songs-play-full p {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    display: block;
    margin-bottom: 0;
}

.stores-buttons {
    display: flex;
    float: right;
    height: 70px;
    align-items: center;
    align-content: center;
}

.store-one {
    margin-right: 20px;
}

#listContainer li a h5 span {
    font-size: 10px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
    display: block;
}

input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #b79a20 !important;
    height: 2px;
    width: 100%;
    transform: rotate(0deg);
    position: absolute;
    left: 34px;
    top: 23px;
    border-radius: 5px;
    border: none !important;
    outline: 0;
    z-index: 9999;
    box-shadow: none !important;
    opacity: 1 !important;
    visibility: visible !important;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #b79a20;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

input[type='range']::-moz-range-thumb {
    border: 1px solid #b79a20;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

input[type='range']::-ms-thumb {
    height: 12px;
    width: 12px;
    border: 1px solid #b79a20;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

#playerContainer {
    display: inline-block;
    height: auto;
    margin-top: 40px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: block;
}

.clearfix {
    overflow: auto;
}

.controls {
    padding-left: 0 !important;
    display: block;
    width: 100%;
}

.controls-firstline {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.player-icon {
    display: block;
    float: left;
    width: 55px;
}

.player-progress {
    display: flex;
    float: left;
    width: 80%;
    padding-bottom: 7px;
}

.player-time {
    display: block;
    float: right;
    text-align: center;
    width: 55px;
}

.playAudio,
.play {
    background: url('../../resources/images/audio/play.png') no-repeat;
    width: 34px;
    height: 34px;
    margin: 0 7.5px;
    cursor: pointer;
    background-size: contain;
    filter: sepia(100%);
}

.pauseAudio,
.pause {
    background: url('../../resources/images/audio/pause.png') no-repeat;
    width: 34px;
    height: 34px;
    margin: 0 7.5px;
    cursor: pointer;
    background-size: contain;
}

.shuffle {
    background: url('../../resources/images/audio/shuffle.png') no-repeat;
    width: 16px;
    height: 64px;
}

.shuffleActive,
.shuffle:hover {
    background: url('../../resources/images/audio/shuffle.png') no-repeat;
    width: 16px;
    height: 64px;
}

.left {
    background: url('../../resources/images/audio/prev.png') no-repeat;
    height: 64px;
    width: 16px;
    margin: auto;
    margin-top: 0px;
    opacity: 1;
    cursor: pointer;
}

.left:not(.disabled):hover {
    opacity: 0.4;
}

.left.disabled,
.right.disabled {
    opacity: 0.4;
    cursor: default;
}

.right {
    background: url('../../resources/images/audio/next.png') no-repeat;
    height: 64px;
    width: 16px;
    margin: auto;
    margin-top: 0px;
    opacity: 1;
    cursor: pointer;
}

.right:not(.disabled):hover {
    opacity: 0.4;
}

.repeat {
    background: url('../../resources/images/audio/replay.png') no-repeat;
    width: 16px;
    height: 64px;
}

.repeat:hover,
.repeat.loopActive {
    background: url('../../resources/images/audio/replay.png') no-repeat;
    width: 16px;
    height: 64px;
}

.mute {
    background: url('../../resources/images/audio/mute.png') no-repeat;
    margin-top: -8px;
}

.progress {
    display: block;
    position: relative;
    background-color: transparent !important;
    width: 100%;
    cursor: pointer;
    box-shadow: none !important;
}

.progress .updateProgress {
    width: 0%;
    background-color: #b79a20;
    height: 100%;
    float: left;
    position: relative;
}

.now-playing {
    font-size: 12px;
    color: white;
}

.now-playing span {
    font-size: 16px;
    font-weight: 600;
    color: white;
}

input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #00bd9b;
    height: 2px;
}

.audioDetails {
    clear: both;
    color: #00bd9b;
    font-size: 12px;
    padding: 5px;
}

.audioTime {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 7px;
}

.songPlay {
    display: inline-block;
}

.seekableTrack {
    width: 0%;
    background-color: #ffffff !important;
    height: 2px;
    position: absolute;
    z-index: 0;
    display: block;
    left: 0px !important;
    top: 61% !important;
}

.seekableTrack,
.progress .updateProgress {
    -webkit-transition: width 0.6s ease;
    -moz-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

.repeat,
.shuffle {
    margin: 0 10.5px;
    cursor: pointer;
}

.bar {
    transition: 0.4s;
}

.store-one {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #343a40;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    user-select: none;
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
    transition: all 0.5s ease-out;
}

.store-one:hover {
    color: #fff;
    background-color: #b79a20;
    border-radius: 5px;
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in;
}

@media only screen and (max-width: 550px) {
    .album-right-column {
        padding: 20px 10px !important;
    }
}

@media only screen and (max-width: 414px) {
    #playerContainer {
        margin-bottom: 40px;
    }

    #listContainer li {
        height: 70px;
    }

    .album-right-column {
        padding: 20px 30px !important;
    }

    .songs-play-full {
        margin-left: 0;
    }

    .songs-play-full {
        min-width: 180px;
    }

    .songs-play-full p {
        font-size: 12px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 360px) {
    #listContainer li {
        height: 75px;
    }

    .songs-play-full {
        min-width: 100px;
        max-width: 155px;
    }

    .songs-play-full p {
        font-size: 10px;
    }

    .album-right-column {
        padding: 20px 10px !important;
    }
}
